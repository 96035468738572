<script setup lang="ts">
import type { Concession } from '#gql/default'
import { breakpointsTailwind } from '@vueuse/core'

const { t } = useI18n()
const { icons } = useDesign()

interface Props {
  concession: Concession
}

const { concession } = defineProps<Props>()
const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndLarger = breakpoints.greaterOrEqual('lg')
const size = computed(() =>
  lgAndLarger.value ? BUTTON_SIZE.SM : BUTTON_SIZE.XS,
)

defineOptions({
  name: 'ConcessionConfigureButtonQuickAdd',
})
</script>

<template>
  <KButton
    :theme="BUTTON_THEME.PRIMARY"
    :size
    :icon="icons.PLUS"
    :title="t('btn.add', { concession: concession.name })"
    class="absolute right-4 top-3 sm:right-3 sm:top-3"
  />
</template>

<i18n>
de:
  btn:
    add: "{concession} hinzufügen"
en:
  btn:
    add: "Add {concession}"
es:
  btn:
    add: "Agregar {concession}"
</i18n>
