<script setup lang="ts">
import type { Cinema } from '#gql/default'
import { ROUTE_NAMES } from '@portal/constants/routes'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

const { t } = useI18n()
const route = useRoute()
const localeRoute = useLocaleRoute()
const getRouteBaseName = useRouteBaseName()

const links = computed(() => {
  const routes = [
    {
      label: t('program'),
      routeName: ROUTE_NAMES.CINEMA_PROGRAM,
      shouldRender: true,
    },
    {
      label: t('info'),
      routeName: ROUTE_NAMES.CINEMA_INFO,
      shouldRender: true,
    },
    {
      label: t('vouchers'),
      routeName: ROUTE_NAMES.CINEMA_VOUCHERS,
      shouldRender: cinema.isVoucherBuyEnabled,
    },
    {
      label: t('shop'),
      routeName: ROUTE_NAMES.CINEMA_SHOP,
      shouldRender: cinema.isShopEnabled,
    },
    {
      label: t('faq'),
      routeName: ROUTE_NAMES.CINEMA_FAQ,
      shouldRender: !!cinema.faq,
    },
  ]

  return routes
    .filter(({ shouldRender }) => shouldRender)
    .map(({ routeName, label }, index) => ({
      label,
      to: localeRoute({ name: routeName }),
      value: index.toString(),
    }))
})

const activeTabIndex = computed(() => {
  const currentRoute = getRouteBaseName(route)
  return links.value
    .findIndex((link) => getRouteBaseName(link.to as any) === currentRoute)
    .toString()
})

defineOptions({
  name: 'CinemaNavigation',
})
</script>

<template>
  <PageContainer class="!pt-0">
    <PageGrid>
      <PageGridColumn>
        <KTabs :items="links" v-model="activeTabIndex" :content="false" />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<i18n>
de:
  program: "Programm"
  info: "Über"
  vouchers: "Gutscheine"
  shop: "Shop"
  birthday: "Geburtstag"
  cinema: "kino"
  faq: "FAQ"
es:
  program: "Programación"
  info: "Información"
  vouchers: "Tarjeta regalo"
  shop: "Tienda"
  birthday: "Cumpleaños"
  cinema: "cine"
  faq: "FAQ"
en:
  program: "Program"
  info: "Info"
  vouchers: "Vouchers"
  shop: "Shop"
  birthday: "Birthday"
  cinema: "cinema"
  faq: "FAQ"
</i18n>
